import React from "react";
import { Scope } from "@unform/core";
import { ChevronDown, Search as IconSearch, Plus } from "react-feather";

import { Input, Modal, P2, Select, SimpleSelect } from "~/components";
import { reduce } from "~/hooks";
import { getDiffDates } from '~/hooks/helpers';

import { DiligenceSearch } from "../styles";
import { AssociateModal } from "../AssociateModal";

import { useSearch } from "../../context/SearchProvider";

export const Diligence = ({ submitDiligence }) => {
  const {
    t,
    ref,
    tag,
    mask,
    tags,
    show,
    person,
    setTag,
    onShow,
    tEntity,
    loading,
    onModal,
    persons,
    options,
    onPerson,
    filtered,
    workflows,
    onWorkflow,
    jurisdiction,
    onJurisdiction,
    hasJurisdiction,
    showOpenDiligenceForceModal,
    setOpenShowDiligenceForceModal,
    lastSameAssessment,
  } = useSearch();

  const getSamePeriodModalContent = () => {
    if(lastSameAssessment) {
      let lastAssessmentDate = new Date(lastSameAssessment['dataabertura']);

      const { isSingular, value, label } = getDiffDates(new Date(),lastAssessmentDate);
      let formatText = ` ${value} `
      formatText += isSingular ? `${t(label).splice(0,-1)} ` :`${t(label)} `;

      let modalContent = t("components.identical_prefix");
      modalContent +=   `${formatText}`;
      modalContent += t("components.identical_sufix");
      return modalContent;
    }
  }

  const modalContent = getSamePeriodModalContent();

  const handlePersonTypeSwitch = (val) => {
    onPerson(val);
    onWorkflow([]);
    onJurisdiction(null);
    ref.current.setFieldValue("document", "");
    ref.current.setFieldValue("workflows", []);
    ref.current.setFieldValue("personWorkflows", []);
    ref.current.setFieldValue("entityWorkflows", []);
  };

  return (
    <DiligenceSearch hasMoreField={hasJurisdiction}>
      <div>
        <SimpleSelect
          name="personType"
          value={person}
          icon={ChevronDown}
          isLoading={!persons}
          isSearchable={false}
          options={options.person}
          onChange={handlePersonTypeSwitch}
          placeholder={t("components.person")}
        />

        {hasJurisdiction && (
          <Select
            name="jurisdiction"
            icon={IconSearch}
            isLoading={!jurisdiction}
            options={options.jurisdiction}
            placeholder={t("components.jurisdiction")}
            onChange={(op) => onJurisdiction(op?.value)}
          />
        )}

        {hasJurisdiction ? (
          <Input
            name="name_jurisdiction"
            disabled={!person}
            placeholder={t("components.name_jurisdiction")}
          />
        ) : (
          <Input
            name="document"
            mask={mask[person?.value] || ""}
            disabled={!person}
            placeholder={
              person?.value === 1
                ? t("components.key.0")
                : person?.value === 2
                ? t("components.key.1")
                : t("components.key.2")
            }
          />
        )}

        <Select
          name="workflows"
          icon={ChevronDown}
          options={options.workflows}
          disabled={!person}
          onChange={(val) => {
            onShow(false);
            onWorkflow(val);
          }}
          isLoading={!workflows}
          placeholder={t("components.workflows")}
        />

        <SimpleSelect
          icon={ChevronDown}
          value={tag}
          options={options.tags}
          disabled={!person}
          onChange={(val) => setTag(val)}
          isLoading={!tags}
          placeholder={t("components.tag.0")}
          addTags={{
            icon: <Plus />,
            label: t("components.tag.1"),
            onClick: () =>
              onModal({
                type: "tags",
                reveal: true,
              }),
          }}
          clearField={{
            label: t("components.tag.2"),
            onClick: () => setTag(""),
          }}
        />
      </div>

      <AssociateModal />

      <Modal
        title={t("components.identical_assessment")}
        show={showOpenDiligenceForceModal}
        onShow={setOpenShowDiligenceForceModal}
        action={{
          label: tEntity("cadastral_data.fields.yes"),
          onClick: async () => {
            setOpenShowDiligenceForceModal(false);
            submitDiligence(false, "forceOpen", true);
          },
        }}
        cancelName={tEntity("cadastral_data.fields.no")}
        showCancel={true}
        loading={loading}
        classWrapper={"modal-advanced-options"}
        prepaidDescription={modalContent}
        noMinHeight={true}
      ></Modal>

      {show && (
        <div>
          {filtered()?.map((item, index) => (
            <>
              <P2
                dangerouslySetInnerHTML={{
                  __html: `${t("components.extra_field")} <b>${
                    item.workflow_name
                  }</b>`,
                }}
              />

              <Input
                name={`w${index}`}
                value={item.workflow_id}
                reveal={false}
              />

              <div>
                {item.items?.map((child, idx) => (
                  <Scope key={child.id} path={`risks[${idx}]`}>
                    <Input name="id" value={child.id} reveal={false} />
                    <Input
                      name="mandatory"
                      value={child.mandatory}
                      reveal={false}
                    />

                    <Input
                      name="risk_source_id"
                      value={child.risk_source_id}
                      reveal={false}
                    />

                    <Input
                      name="value"
                      label={reduce(child.fonte_risco_name, 50)}
                      placeholder={`${child.name}${
                        child.mandatory ? " *" : ""
                      }`}
                    />
                  </Scope>
                ))}
              </div>
            </>
          ))}
        </div>
      )}
    </DiligenceSearch>
  );
};
